import facebookSdk from 'libs/facebookSdk'
export default {
  props: {
    url: {
      type: String,
      default: null,
    },
  },
  created() {
    this.facebookSdk.setup(this.facebookAppId, this.language)
  },
  methods: {
    shareMessenger() {
      if(!this.facebookAppId) return
      if(!window.FB) return
      FB.ui({
        method: 'share',
        href: this.shareUrl,
      })
    },
  },
  computed: {
    facebookSdk() {
      return facebookSdk
    },
    language() {
      return this.$store.getters['base/siteLanguage']
    },
    application() {
      return this.$store.getters['base/application']
    },
    oauthConfig() {
      if(!this.application) return null
      return this.application.oauth
    },
    facebookAppId() {
      if(!this.oauthConfig) return null
      return this.$helper.getRecursive(this.oauthConfig, 'facebook.client')
    },
    shareUrl() {
      if(this.url) return this.url
      const origin = window.location.origin
      let path = window.location.pathname
      path = path.substring(1)
      let result = `${origin}${process.env.BASE_URL}${path}`
      return result
    },
    facebookShareUrl() {
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.shareUrl)}`
    },
    lineShareUrl() {
      return `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(this.shareUrl)}`
    },
    twitterShareUrl() {
      return `http://twitter.com/share?url=${encodeURIComponent(this.shareUrl)}`
    },
    linkedInShareUrl() {
      return `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(this.shareUrl)}`
    },
  },
}